var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.infoshow)?_c('div',[_c('div',{staticClass:"item_line"},[_c('span',{staticClass:"titlename",staticStyle:{"font-size":"18px","color":"#333","font-weight":"bold"}},[_vm._v(_vm._s(_vm.form.name))])]),_c('div',{staticClass:"item_line"},[_c('span',{staticClass:"titlename"},[_vm._v(_vm._s(_vm.form.wymeetingtype_name))])]),_c('div',{staticClass:"item_line"},[_vm._m(0),_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.activity_start_time))])]),_c('div',{staticClass:"item_line"},[_vm._m(1),_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.start_time)+" 至 "+_vm._s(_vm.form.end_time))])]),_c('div',{staticClass:"item_line"},[_vm._m(2),_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.address))])]),_c('div',{staticClass:"item_line",staticStyle:{"align-items":"flex-start"}},[_vm._m(3),_c('div',{staticStyle:{"width":"80%"}},[_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.user_list.map((item)=>{return item.name}).join(','))+";")])])]),_c('div',{staticClass:"item_line",staticStyle:{"align-items":"flex-start"}},[_vm._m(4),_c('div',{staticStyle:{"width":"80%"}},[_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.content))])])]),_c('div',{staticClass:"item_line",staticStyle:{"align-items":"flex-start"}},[_vm._m(5),_c('div',{staticStyle:{"width":"80%"}},_vm._l((_vm.form.files),function(item,index){return _c('div',{staticStyle:{"margin-bottom":"10px","display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"flex":"5","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_c('a',{attrs:{"href":item.fullurl,"target":"_blank"}},[_vm._v(_vm._s(item.filename))])])])}),0)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" 活动时间：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" 报名时间：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-location-information"}),_vm._v(" 活动地点：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-user"}),_vm._v(" 参加人员：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-document"}),_vm._v(" 活动详情：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-document"}),_vm._v(" 附件：")])
}]

export { render, staticRenderFns }
<template>
	<div v-if="infoshow">
		<div class="item_line">
			<span class="titlename" style="font-size: 18px;color: #333;font-weight: bold;">{{form.name}}</span>
		</div>
		<div class="item_line">
			<span class="titlename">{{form.wymeetingtype_name}}</span>
		</div>
		<div class="item_line">
			<span class="titlename"> <i class="el-icon-time"></i> 活动时间：</span>
			<span class="valuename">{{form.activity_start_time}}</span>
		</div>
		<div class="item_line">
			<span class="titlename"> <i class="el-icon-time"></i> 报名时间：</span>
			<span class="valuename">{{form.start_time}} 至 {{form.end_time}}</span>
		</div>
		<div class="item_line">
			<span class="titlename"> <i class="el-icon-location-information"></i> 活动地点：</span>
			<span class="valuename">{{form.address}}</span>
		</div>
		<div class="item_line" style="align-items: flex-start;">
			<span class="titlename"> <i class="el-icon-user"></i> 参加人员：</span>
			<div style="width: 80%;">
				<span class="valuename">{{form.user_list.map((item)=>{return item.name}).join(',')}};</span>
			</div>
		</div>
		<div class="item_line" style="align-items: flex-start;">
			<span class="titlename"> <i class="el-icon-document"></i> 活动详情：</span>
			<div style="width: 80%;">
				<span class="valuename">{{form.content}}</span>
			</div>
			
		</div>
		<div class="item_line" style="align-items: flex-start;">
			<span class="titlename"> <i class="el-icon-document"></i> 附件：</span>
			<div style="width: 80%;">
				<div v-for="(item, index) in form.files" style="margin-bottom: 10px; display: flex; align-items: center">
					<div style="
				  flex: 5;
				  overflow: hidden;
				  white-space: nowrap;
				  text-overflow: ellipsis;
				">
						<a :href="item.fullurl" target="_blank">{{ item.filename }}</a>
					</div>
				</div>
			</div>
			
		</div>
		
		
	</div>

</template>

<script>
	export default {
		data() {
			return {
				infoshow: false,
				form: ''
			}
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.tapmenu {
		font-size: 16px;
		margin-right: 16px;
		cursor: pointer;
		padding-bottom: 5px;
	}

	.active_tap {
		color: #ec4a4a;
		border-bottom: 2px solid #ec4a4a;
	}

	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		.titlename {
			margin-left: 10px;
			font-size: 14px;

			text-align: left;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		height: 85vh;
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;

	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		padding: 10px 0;
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-form-item__error {
		padding-top: 0;
	}

	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: '' !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	::v-deep .myinputicon {
		cursor: pointer;
	}

	::v-deep .myinputicon:hover {
		color: red !important;
	}
</style>
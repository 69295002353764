<template>
	<div v-show="show" style="background-color: rgba(0,0,0,0.3);width: 100vw;height:100vh;z-index: 9999;position: fixed;top: 0;left: 0;">
		<el-dialog
		  title="报名管理"
		  :visible.sync="show"
		  width="50%">
		  <div style="width: 100%;height: 60vh;overflow-y:auto;padding: 20px;font-size: 16px;">
			  <div style="margin-bottom: 10px;">已报名人数<span style="color:#277bf5">({{bmList.length}}人)</span></div>
			  
			  <div style="width:100%;">
			  	<div style="display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 10px 0;border-bottom: 1px solid #eee;" v-for="item in bmList">
			  		<div style="color: #333;font-size:16px;font-weight: bold;">
			  			{{item.user.name?item.user.name:'报名人员'}}
			  		</div>
			  		<div style="font-size: 14px;">
			  			<span style="margin-right: 10px;">报名时间：</span><span style="color:#333;">{{item.join_time}}</span>
			  		</div>
			  	</div>
			  </div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="show = false">取 消</el-button>
		    <el-button type="primary" @click="show = false">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				show:false,
				id:'',
				bmList:[]
			}
		},
		methods:{
			getlist(){
				this.$post({
					url: '/api/wymeeting/hyry',
					params: {
						wymeeting_id:this.id,
						type:3
					}
				}).then((res) => {
					this.bmList = res.data.list
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	
</style>